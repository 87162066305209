.blog-box{

  display: block;
  margin-bottom: 45px;
  color: inherit;

  img{

    max-width: 100%;

  }

  .title{

    color: inherit;
    letter-spacing: 0;
    line-height: 1.3;

  }

  .date{

    color: #999999;

  }

}
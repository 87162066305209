/* extra small to medium screen*/
@media (max-width: 1199px){

  body.menu-opened{
    overflow-y: hidden;
  }

  .company-logo a{
    height: 38px;
    width: 53px;
  }

  .company-logo a img{
    transform: translateX(-54px);
  }

  body.fixed-header #inner-page,
  body.fixed-header #main-banner{
    padding-top: 58px;
  }

  .main-menubar-toggle{
    height: 40px;
    width: 30px;
    position: absolute;
    right: 9%;
    top: 0;
    cursor: pointer;
  }

  .nav-icon {
    position: absolute;
    right: 0;
    top: 0;
    margin: 1em;
    width: 28px;
    vertical-align: middle;
    /*opacity: 0.7;*/
    z-index: 99;
  }
  .nav-icon:after, .nav-icon:before, .nav-icon {
    background-color: #fff;
    content: '';
    display: block;
    height: 3px;
    margin: 8px 0 -3px;
    -moz-transition: all .2s ease-in-out;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
  }

  .main-header,
  body.fixed-header .main-header{
    padding: 10px 0;
    height: 58px;
  }

  body.fixed-header .main-header{
    top: -58px;
  }

  body.fixed-header .main-header .nav-icon,
  body.fixed-header .main-header .nav-icon:before,
  body.fixed-header .main-header .nav-icon:after{
    background-color: #000A0D;
  }

  body.menu-opened.fixed-header .main-header .nav-icon:after,
  body.menu-opened.fixed-header .main-header .nav-icon:before{
    background-color: #9AA7AB;
  }

  body.menu-opened .nav-icon,
  body.menu-opened.fixed-header .main-header .nav-icon{
    background: transparent;
    transform: translateX(5px);
  }

  body.menu-opened .nav-icon:before{
    transform: rotate(45deg) translate(0, 6px);
  }

  body.menu-opened .nav-icon:after{
    transform: rotate(-45deg) translate(0, -6px);
  }

  body.menu-opened .main-menubar{
    visibility: visible;
    opacity: 1;
    padding: 0 20px;
  }
  .main-menubar {
    background-color : rgba( 0, 10, 13, 1 );
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left:0;
    z-index: 9;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s;
    overflow-y: auto;
  }
  .main-menubar ul{
    display: block;
    max-width: 350px;
    margin: 0 auto;
    margin-top: 18%;
  }
  .main-menubar ul li{
    width: auto;
    float: none;
    display: block;
    opacity: 0;
  }
  .main-menubar ul li{
    margin-bottom: 50px;
    text-align: left;
    padding: 0;
  }
  .main-menubar ul li a{
    margin: 0;
  }
  .main-menubar ul li a:not(.btn),
  body.fixed-header .main-header .main-menubar ul li a:not(.btn){
    color: rgba( 255, 255, 255, 0.8 );
  }
  .main-menubar ul li a:not(.btn){
    font-size: 36px;
    font-family: "Merriweather", serif;
    letter-spacing: 2px;
    transition: transform 0.1s;
  }
  .main-menubar ul li a:not(.btn):active{
    transform: scale(0.95);
  }
  .main-menubar ul li .btn{
    font-size: 18px;
    font-weight: 800;
    padding: 10px 15px;
  }

  /*Animate menu*/
  body.menu-opened .main-menubar ul li{
    animation: FadeInUp 0.2s cubic-bezier(0, 0.17, 0.67, 1);
    -webkit-animation: FadeInUp 0.2s cubic-bezier(0, 0.17, 0.67, 1);
    -moz-animation: FadeInUp 0.2s cubic-bezier(0, 0.17, 0.67, 1);
    -o-animation: FadeInUp 0.2s cubic-bezier(0, 0.17, 0.67, 1);
    -ms-animation: FadeInUp 0.2s cubic-bezier(0, 0.17, 0.67, 1);
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    -ms-animation-fill-mode: forwards;
  }
  /* body.menu-opened .main-menubar ul li:nth-child(1){
       animation-delay: 0s;
       -webkit-animation-delay: 0s;
       -moz-animation-delay: 0s;
       -ms-animation-delay: 0s;
       -o-animation-delay: 0s;
   }*/
  body.menu-opened .main-menubar ul li:nth-child(1){
    animation-delay: 0.05s;
    -webkit-animation-delay: 0.05s;
    -moz-animation-delay: 0.05s;
    -ms-animation-delay: 0.05s;
    -o-animation-delay: 0.05s;
  }
  body.menu-opened .main-menubar ul li:nth-child(2){
    animation-delay: 0.1s;
    -webkit-animation-delay: 0.1s;
    -moz-animation-delay: 0.1s;
    -ms-animation-delay: 0.1s;
    -o-animation-delay: 0.1s;
  }
  body.menu-opened .main-menubar ul li:nth-child(3){
    animation-delay: 0.15s;
    -webkit-animation-delay: 0.15s;
    -moz-animation-delay: 0.15s;
    -ms-animation-delay: 0.15s;
    -o-animation-delay: 0.15s;
  }
  body.menu-opened .main-menubar ul li:nth-child(4){
    animation-delay: 0.2s;
    -webkit-animation-delay: 0.2s;
    -moz-animation-delay: 0.2s;
    -ms-animation-delay: 0.2s;
    -o-animation-delay: 0.2s;
  }
  body.menu-opened .main-menubar ul li:nth-child(5){
    animation-delay: 0.25s;
    -webkit-animation-delay: 0.25s;
    -moz-animation-delay: 0.25s;
    -ms-animation-delay: 0.25s;
    -o-animation-delay: 0.25s;
  }

  .capabilities-post-header ul li a span {
    display: none;
  }

  .capabilities-post-header ul li i {
    margin: 0 10px;
    padding: 0;
  }
}

/* Extra small to small */
@media (max-width: 991px) {
  .slick-arrow{
    display: none !important;
  }

  .modal{
    background: #000A0D;
  }

  .iso-grid li{
    width: 50%;
  }
}

/* Large to small screen */
@media (min-width: 768px) {

  .owl-carousel.has-nav{
    padding: 0 100px;
    position: relative;
  }

  .owl-carousel.has-nav .owl-prev,
  .owl-carousel.has-nav .owl-next{
    position: absolute;
    top: 50%;
  }

  .owl-carousel.has-nav .owl-prev{
    left: 0;
  }

  .owl-carousel.has-nav .owl-next{
    right: 0;
  }
}

@media (max-width: 991px){
  .site-content.with-sidebar .left-sidebar-wrap{
    display: none;
  }
  .site-content.with-sidebar .main-content{
    width: auto;
    float: none;
    margin-left: 0;
  }
}

/* Extra small */
@media (max-width: 767px) {

  .push-down-xs{
    margin-bottom: 20px !important;
  }

  .push-up-xs{
    margin-top: 20px !important;
  }

  .hug-down-xs{
    margin-bottom: 0 !important;
  }

  .hug-up-xs{
    margin-top: 0 !important;
  }

  .text-right-xs{
    text-align: right !important;
  }

  .text-left-xs{
    text-align: left !important;
  }

  .pull-right-xs{
    float: right !important;
  }

  .pull-left-xs{
    float: left !important;
  }


  .owl-carousel .owl-buttons{
    display: none;
  }


  .main-banner-slider,
  #inner-page .main-banner-content-wrap .main-banner-content p,
  #inner-page .main-banner-content-wrap .main-banner-content h1,
  .intro,
  .site-section-title:not(.center),
  .some-intro{
    text-align: left;
  }

  /*.site-section.attach-top-xs{
      padding-top: 0;
  }*/

  .main-banner-slider{
    padding: 15% 0;
  }

  .main-banner-slider .title{
    font-size: 42px;
    line-height: 52px;
  }

  .main-banner-slider .content{
    font-size: 16px;
    line-height: 26px;
  }

  .main-page-head.responsive h1{
    font-size: 36px;
    line-height: 46px;
  }

  .main-page-head.responsive p{
    font-size: 18px;
    line-height: 28px;
  }

  #inner-page{
    max-height: 100vh;
  }

  .form-modernize.responsive .form-control{
    font-size: 18px;
    padding: 21px;
  }
  .form-modernize textarea.form-control{
    padding-top: 14px !important;
    padding-bottom: 14px !important;
  }

  .iso-filter li{
    width: 50%;
  }

  .iso-filter a{
    font-size: 12px;
  }

  .iso-grid li{
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }

  .contact-wrap{
    padding: 15px;
  }
}

/* Small Screen Only */
@media (min-width: 768px) and (max-width: 991px) {

  .push-down-sm{
    margin-bottom: 20px !important;
  }

  .push-up-sm{
    margin-top: 20px !important;
  }

  .hug-down-sm{
    margin-bottom: 0px !important;
  }

  .hug-up-sm{
    margin-top: 0px !important;
  }

  .text-right-sm{
    text-align: right !important;
  }

  .text-left-sm{
    text-align: left !important;
  }

  .pull-right-sm{
    float: right !important;
  }

  .pull-left-sm{
    float: left !important;
  }

}
/* Medium Screen Only */
@media (min-width: 992px) and (max-width: 1199px) {

  .push-down-md{
    margin-bottom: 20px !important;
  }

  .push-up-md{
    margin-top: 20px !important;
  }

  .hug-down-md{
    margin-bottom: 0px !important;
  }

  .hug-up-md{
    margin-top: 0px !important;
  }

  .text-right-md{
    text-align: right !important;
  }

  .text-left-md{
    text-align: left !important;
  }

  .pull-right-md{
    float: right !important;
  }

  .pull-left-md{
    float: left !important;
  }

  .site-content.with-sidebar .left-sidebar-wrap{
    width: 30%;
  }

  .site-content.with-sidebar .main-content {
    width: 67%;
  }

}
/* Large Screen Only */
@media (min-width: 1200px) {

  .push-down-lg{
    margin-bottom: 20px !important;
  }

  .push-up-lg{
    margin-top: 20px !important;
  }

  .hug-down-lg{
    margin-bottom: 0px !important;
  }

  .hug-up-lg{
    margin-top: 0px !important;
  }

  .text-right-lg{
    text-align: right !important;
  }

  .text-left-lg{
    text-align: left !important;
  }

  .pull-right-lg{
    float: right !important;
  }

  .pull-left-lg{
    float: left !important;
  }
  /* Large Devices, Wide Screens */

  @media only screen and (max-width : 1920px) {
  }
  @media only screen and (max-width : 1280px) {
    .slick-prev:before, .slick-next:before {
      display: none;
    }
    .mega-menu-wrap {
      padding-top: 50px;
    }
  }

  /*======================================================================*/

  @media only screen and (max-width : 1024px) {
    .service-post {
      min-width: 41%;
      min-height: 300px;
    }
    .checklist-content {
      max-width: 365px;
    }
    .inner-menu{
      display: none;
    }

  }

  /*======================================================================*/
  @media only screen and (max-width : 979px) {
    .service-block-wrap {
      margin: 15px 10px;
    }
    .service-post {
      width: 48%;
    }
    .checklist-content {
      max-width: 80%;
    }
    .portfolio-item-post.item-post-sm {
      width: 50%;
      margin-bottom: 20px;
    }
  }
  /*=======================================================================*/
  @media only screen and (max-width : 768px){
    .portfolio-item-post {
      width: 50%;
    }
  }
  /*======================================================================*/


  /* Extra Small Devices, Phones */
  @media only screen and (max-width : 640px) {

  }

  /*======================================================================*/

  /* Extra Small Devices, Phones */
  @media only screen and (max-width : 480px) {
    .header-bar .top-block{
      display: none;
    }
    .caption-wrap{
      width: 100%;
      margin-top: 150px;
    }
    .service-post{
      width: 47%;
    }
    .checklist-icon{
      float: none;
      margin: 0 auto 15px;
    }
    .checklist-content {
      text-align: center;
      max-width: 100%;
    }
    .portfolio-item-post.item-post-sm{
      width: 100%;
    }
    .tab-content-wrap ul.nav-tabs li a{
      width: 50px;
      height: 50px;
      padding: 5px;
    }
    .tab-content-wrap ul.nav-tabs li a span {
      font-size: 26px;
    }
    .tab-content-wrap .nav-tabs >
    li.active > a, .nav-tabs > li.active > a:focus,
    .nav-tabs > li.active > a:hover{
      width: 50px;
      height: 50px;
      padding: 5px;
    }
    .portfolio-item-post {
      width: 100%;
    }
    a.know-more.scrollTo{
      display: none;
    }
  }
  /*======================================================================*/
  /* Custom, iPhone Retina */
  @media only screen and (max-width : 320px) {
    a.service-post{
      width: 100%;
    }
  }

}

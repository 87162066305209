/*
============================
Padding
============================
*/

// Padding
@for $i from 0 through 140{
  .p-#{$i * 5}{
    padding: 5px * $i;
  }
}

// Padding-top
@for $i from 0 through 140{
  .p-t-#{$i * 5}{
    padding-top: 5px * $i;
  }
}

// Padding-right
@for $i from 0 through 140{
  .p-r-#{$i * 5}{
    padding-right: 5px * $i;
  }
}

// Padding-bottom
@for $i from 0 through 140{
  .p-b-#{$i * 5}{
    padding-bottom: 5px * $i;
  }
}

// Padding-left
@for $i from 0 through 140{
  .p-l-#{$i * 5}{
    padding-bottom: 5px * $i;
  }
}

// Padding-vertical
@for $i from 0 through 140{
  .p-v-#{$i * 5}{
    padding-top: 5px * $i;
    padding-bottom: 5px * $i;
  }
}

// Padding-horizontal
@for $i from 0 through 140{
  .p-h-#{$i * 5}{
    padding-left: 5px * $i;
    padding-right: 5px * $i;
  }
}

/*
============================
Margin
============================
*/

// Margin
@for $i from 0 through 140{
  .m-#{$i * 5}{
    margin: 5px * $i;
  }
}

// Margin-top
@for $i from 0 through 140{
  .m-t-#{$i * 5}{
    margin-top: 5px * $i;
  }
}

// Margin-right
@for $i from 0 through 140{
  .m-r-#{$i * 5}{
    margin-right: 5px * $i;
  }
}

// Margin-bottom
@for $i from 0 through 140{
  .m-b-#{$i * 5}{
    margin-bottom: 5px * $i;
  }
}

// Margin-left
@for $i from 0 through 140{
  .m-l-#{$i * 5}{
    margin-bottom: 5px * $i;
  }
}

// Margin-vertical
@for $i from 0 through 140{
  .m-v-#{$i * 5}{
    margin-top: 5px * $i;
    margin-bottom: 5px * $i;
  }
}

// Margin-horizontal
@for $i from 0 through 140{
  .m-h-#{$i * 5}{
    margin-left: 5px * $i;
    margin-right: 5px * $i;
  }
}
body{
  font-family: 'Merriweather', serif;
  font-weight: 400;
  color: rgba(0,10,13,0.8);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6{
  font-weight: 800;
  font-family: 'Merriweather Sans', sans-serif;
  letter-spacing: 1px;
//  color: #000A0D;
}

.text-primary{
  color: #FDD500;
}

.text-thin{
  font-weight: 300;
}

.text-lg{
  font-size: 24px;
  line-height: 38px;
}

.text-huge{
  font-size: 48px;
  line-height: 58px;
  margin-bottom: 38px;
}

.text-lead{
  font-size: 21px !important;
  line-height: 38px;
}

.text-med{
  font-size: 18px !important;
  line-height: 28px;
}

.text-small{
  font-size: 14px;
  line-height: 2rem;
}

b,
strong{
  font-weight: 700;
}
@font-face{
  font-family: 'Merriweather';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/Merriweather/Merriweather-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Merriweather';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/Merriweather/Merriweather-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Merriweather';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/Merriweather/Merriweather-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Merriweather';
  font-style: italic;
  font-weight: 700;
  src: url('../fonts/Merriweather/Merriweather-BoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Merriweather';
  font-style: normal;
  font-weight: 900;
  src: url('../fonts/Merriweather/Merriweather-Black.ttf') format('truetype');
}

@font-face {
  font-family: 'Merriweather Sans';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/Merriweather_Sans/MerriweatherSans-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Merriweather Sans';
  font-style: normal;
  font-weight: 800;
  src: url('../fonts/Merriweather_Sans/MerriweatherSans-ExtraBold.ttf') format('truetype');
}
.bmodal{

  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba( 0,0,0,0.9 );
  z-index: 99;
  overflow-y: auto;
  padding-top: 30px;
  padding-bottom: 30px;
  @include transition( opacity 0.5s );


  &.bmodal-enter,
  &.bmodal-leave-active{
    opacity: 0;
  }

  &.bmodal-enter .dialog,
  &.bmodal-leave-active .dialog{
    @include translate( 0, 30px );
  }

  .dialog{
    @include transition( all 0.5s );
    position: relative;
    margin: 0 auto;
  }

  &.bmodal-normal{

    &.bmodal-full{

      .dialog{

        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

      }

    }

    .dialog{

      background: #ffffff;

      .close-btn{

        position: absolute;
        top: 10px;
        right: 10px;
        background: none;
        border: none;

        &:focus{

          outline: none;

        }

        &:active{

          @include scale( 0.95 );

        }

      }

    }

  }

  &.bmodal-dark{

    .dialog{

      max-width: 600px;
      color: #e1e1e1;

      .close-btn{

        position: absolute;
        top: 15px;
        right: 15px;
        color: inherit;
        background: none;
        border: none;

        &:focus{

          outline: none;

        }

        &:active{

          @include scale( 0.95 );

        }

      }

      .header{

        text-align: center;
        border-bottom: 1px solid #333333;
        margin-bottom: 30px;
        padding-bottom: 30px;
        padding-top: 30px;
        padding-left: 15px;
        padding-right: 15px;

        .title{

          color: inherit;
          font-size: 48px;
          margin-top: 0;

        }

        .subtitle{

          color: inherit;
          font-size: 18px;
          margin-bottom: 0;

        }

      }

      .body{

        padding: 15px;

      }

    }

  }

}

@media (max-width: $screen-xs-max) {

  .bmodal{

    &.bmodal-dark{

      .dialog{

        .header{

          .title{

            font-size: 28px;

          }

        }

      }

    }

  }

}
// Minimum height
@for $i from 1 through 200{
  .mh-#{$i * 5}{
    min-height: 5px * $i;
  }
}

// Maximum height
@for $i from 1 through 200{
  .mxh-#{$i * 5}{
    max-height: 5px * $i;
  }
}

// Max width
@for $i from 1 through 200{
  .mxw-#{$i * 5}{
    max-width: 5px * $i;
  }
}
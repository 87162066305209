@charset "UTF-8";

@font-face {
  font-family: "bs-icon";
  src:url("../fonts/bs-icon.eot");
  src:url("../fonts/bs-icon.eot?#iefix") format("embedded-opentype"),
  url("../fonts/bs-icon.woff") format("woff"),
  url("../fonts/bs-icon.ttf") format("truetype"),
  url("../fonts/bs-icon.svg#bs-icon") format("svg");
  font-weight: normal;
  font-style: normal;

}

/* way the hell off screen */
.scrollbar-measure {
  width: 100px;
  height: 100px;
  overflow: scroll;
  position: absolute;
  top: -9999px;
}

[data-icon]:before {
  font-family: "bs-icon" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "bs-icon" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-address:before {
  content: "\61";
}
.icon-affordable:before {
  content: "\62";
}
.icon-business-value:before {
  content: "\63";
}
.icon-debug:before {
  content: "\64";
}
.icon-design:before {
  content: "\65";
}
.icon-discover:before {
  content: "\66";
}
.icon-email:before {
  content: "\67";
}
.icon-google-plus:before {
  content: "\68";
}
.icon-instagram:before {
  content: "\69";
}
.icon-menu:before {
  content: "\6a";
}
.icon-mobile-app:before {
  content: "\6b";
}
.icon-on-time:before {
  content: "\6c";
}
.icon-phone:before {
  content: "\6d";
}
.icon-powerful:before {
  content: "\6e";
}
.icon-programming-code:before {
  content: "\6f";
}
.icon-responsive-design-symbol:before {
  content: "\70";
}
.icon-testing:before {
  content: "\71";
}
.icon-twitter:before {
  content: "\72";
}
.icon-update:before {
  content: "\73";
}
.icon-web-hosting:before {
  content: "\74";
}
html{
  font-size: 14px;
}
body{
  overflow: hidden;
}
body.site-loaded{
  overflow-y: auto;
}
a {
  text-decoration: none;
}
a:hover,
a:visited,
a:focus{
  text-decoration: none;
}
p {
  line-height: 2.2rem;
  margin-top: 1.5rem;
  margin-bottom: 30px;
  font-size: 16px;
}
.contact-content p{
  font-size: 16px;
}
h1 {
  font-size: 24px;
  line-height: 2.25rem;
  margin-top: 2.25rem;
}
h2 {
  font-size: 22px;
  line-height: 2rem;
  margin-top: 2rem;
}
h3 {
  font-size: 20px;
  line-height: 2rem;
  margin-top: 1.75rem;
}
h4 {
  font-size: 18px;
  line-height: 1.875rem;
  margin-top: 1.5rem;
}
hr {
  margin-bottom: 0;
}

blockquote{

  font-style: italic;
  font-size: 24px;
  margin: 50px 0;

}

.has-error .checkbox,
.has-error .checkbox-inline,
.has-error .control-label,
.has-error .help-block,
.has-error .radio,
.has-error .radio-inline,
.has-error.checkbox label,
.has-error.checkbox-inline label,
.has-error.radio label,
.has-error.radio-inline label{
  color: #EB302D;
}

.has-error .form-control{
  border-color: #EB302D !important;
}

.hug{
  margin-top: 0;
  margin-bottom: 0;
}

.hug-down{
  margin-bottom: 0;
}

.hug-up{
  margin-top: 0;
}

.push-up{
  margin-top: 20px;
}

.push-down{
  margin-bottom: 20px;
}

.center {
  text-align: center;
}
.container-sm {
  max-width: 790px;
  margin: 0 auto;
}
.container-md {
  max-width: 980px;
  margin: 0 auto;
}
/* ============ Reset csss =============== */

#main-banner {
  min-height: 650px;
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: cover;
  position: relative;
  padding-top: 100px;
}
.overlay{
  background: url(../images/main-banner/overlay-pattern.png);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  min-height: 650px;
}
.company-logo a {
  display: block;
  position: relative;
  height: 64px;
  width: 90px;
  overflow: hidden;
}

.company-logo a img{
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  transform: translateX(-89px);
}

body.fixed-header .company-logo a img{
  transform: translateX(0px);
}

/*.company-logo img{
	width: auto;
	height: 64px;
}*/
.main-header {
  padding: 20px 0;
  height: 104px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
}
.main-header.dark{
  background: rgba(0,10,13, 1);
}
body.fixed-header .main-header{
  background-color: #ffffff;
  top: -104px;
  position: fixed;
  left: 0;
  width: 100%;
  z-index: 99;
  border-bottom: 1px solid #E6E5E5;
  transition: top 0.2s;
}
body.fixed-header .main-header.in{
  top: 0;
}
body.fixed-header #main-banner{
}
.main-menubar {
  padding-top: 15px;
  text-align: right;
}
.main-menubar ul{
  list-style-type: none;
  padding: 0;
  display: inline-block;
  vertical-align: bottom;
  margin: 0;
  line-height: 30px;
}
.main-menubar ul li{
  list-style-type: none;
  display: inline-block;
  text-transform: uppercase;
  padding-right: 45px;
}
.main-menubar ul li > a {
  font-family: "Merriweather Sans", sans-serif;
  color: rgba(255, 255, 255, 0.8);
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 1px;
}

body.fixed-header .main-header .main-menubar ul li > a{
  color: #000A0D;
}
body.fixed-header .main-header .main-menubar ul li .btn-primary:hover,
.btn-primary:active{
  color: #000000 !important;
  background-color: transparent !important;
  border-color: #FDD500 !important;
  outline: none !important;
  letter-spacing: 2px;
}
.btn.loading{
  position: relative;
  padding-left: 34px;
  /*padding-right: 8px;*/
}

.btn.loading:before{
  content: '';
  position: absolute;
  left: 12px;
  top: 12px;
  height: 15px;
  width: 15px;
  background: url("../images/icons/rolling.svg") no-repeat;
  background-position: center;
  background-size: contain;
}

.btn{
  font-family: "Merriweather Sans", sans-serif;
  letter-spacing: 1px;
  font-weight: 700;
  font-size: 12px;
  padding: 12px 20px;
  border-radius: 0;
  text-transform: uppercase;
  transition: all 0.2s;
}

.btn:focus,
.btn:active{
  outline: none !important;
  box-shadow: none;
}

.btn-default{
  background: #E1E4E6;
  border-color: #E1E4E6;
}

.btn-default:hover{
  background: #D7DADB;
  border-color: #D7DADB;
}

.btn-primary,
.btn-primary:visited,
.btn-primary:focus{
  color: rgba(0,10,13,1) !important;
  background-color: #FDD500;
  border-color: #FDD500;
  border-radius: 0;
  text-transform: uppercase;
}
.btn-primary:hover,
.btn-primary:active{
  color: #FFFFFF !important;
  background-color: transparent !important;
  border-color: #FDD500 !important;
  outline: none !important;
  letter-spacing: 2px;
}
.btn-primary.black,
.btn-primary.black:hover,
.btn-primary.black:focus{
  background-color: #000A0D;
  border-color: #000A0D;
  color: #FDD500 !important;
}
.btn-primary.black:hover,
.btn-primary.black:hover:active{
  color: #042029 !important;
  background-color: transparent !important;
  border-color: #042029 !important;
  outline: none !important;
  letter-spacing: 2px;
}

.main-page-head h1 span,
.main-page-head h2 span{
  color: #FDD500;
}
.main-page-head h1,
.main-page-head h2{
  margin-top: 0;
}

.main-page-head h1{
  font-size: 48px;
  line-height: 58px;
}
.main-page-head h2{
  font-size: 36px;
  line-height: 46px;
}

.main-page-head p{
  font-size: 21px;
  font-weight: 300;
  margin-bottom: 0;
}
.main-page-head{
  max-width: 980px;
  margin: 0 auto 70px auto;
}
.main-page-head.mini p{
  font-size: 16px;
  line-height: 26px;
}

.main-banner-slider{
  padding: 10% 0;
  color: #fff;
  text-align: center;
}
.main-banner-slider .title{
  font-size: 48px;
  color: #FDD500;
  line-height: 4rem;
  margin-top: 0;
}
.main-babber-slider span{
  font-size: 18px;
  line-height: 1.875rem;
  margin-top: 1.5rem;
}
.main-banner-slider .title .icon{
  height: 40px;
  width: 40px;
  display: inline-block;
  margin-right: 20px;
  vertical-align: baseline;
}

.main-banner-slider .title .icon:before{
  content: '';
  display: inline-block;
  height: inherit;
  width: inherit;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.main-banner-slider .title .icon.namaste:before{
  background-image: url("../images/main-banner/namaste.png");
}

.main-banner-slider .title .icon.design:before{
  background-image: url("../images/main-banner/design.png");
}

.main-banner-slider .title .icon.market:before{
  background-image: url("../images/main-banner/reach-your-customer.png");
}

.main-banner-slider .title .icon.responsive:before{
  background-image: url("../images/main-banner/responsive.png");
}

.main-banner-slider .subtitle{
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 20px;
}

.main-banner-slider .subtitle,
.main-banner-slider .content{
  color: rgba(255, 255, 255, 0.8);
}
.main-banner-slider .content{
  font-size: 21px;
  font-weight: 300;
}

.main-banner-slider .item.in .title,
.main-banner-slider .item.in .subtitle,
.main-banner-slider .item.in .content,
.main-banner-slider .item.in .btn{
  opacity: 0;
}

.main-banner-slider .item.out .title,
.main-banner-slider .item.out .subtitle,
.main-banner-slider .item.out .content,
.main-banner-slider .item.out .btn{
  opacity: 1;
}

.main-banner-slider .item.in .title{
  animation: FadeInDown 0.4s;
  -webkit-animation: FadeInDown 0.4s;
  -moz-animation: FadeInDown 0.4s;
  -ms-animation: FadeInDown 0.4s;
  -o-animation: FadeInDown 0.4s;
  animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  -ms-animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
  -o-animation-fill-mode: forwards;
}

.main-banner-slider .item.in .subtitle{

  animation: FadeInDown 0.4s;
  -webkit-animation: FadeInDown 0.4s;
  -moz-animation: FadeInDown 0.4s;
  -ms-animation: FadeInDown 0.4s;
  -o-animation: FadeInDown 0.4s;
  animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  -ms-animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
  -o-animation-fill-mode: forwards;
  animation-delay: 0.6s;
  -webkit-animation-delay: 0.6s;
  -moz-animation-delay: 0.6s;
  -ms-animation-delay: 0.6s;
  -o-animation-delay: 0.6s;
}

.main-banner-slider .item.in .content{

  animation: FadeInDown 0.4s;
  -webkit-animation: FadeInDown 0.4s;
  -moz-animation: FadeInDown 0.4s;
  -ms-animation: FadeInDown 0.4s;
  -o-animation: FadeInDown 0.4s;
  animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  -ms-animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
  -o-animation-fill-mode: forwards;
  animation-delay: 0.2s;
  -webkit-animation-delay: 0.2s;
  -moz-animation-delay: 0.2s;
  -ms-animation-delay: 0.2s;
  -o-animation-delay: 0.2s;
}

.main-banner-slider .item.in .btn{
  animation: FadeInUp 0.4s;
  -webkit-animation: FadeInUp 0.4s;
  -moz-animation: FadeInUp 0.4s;
  -ms-animation: FadeInUp 0.4s;
  -o-animation: FadeInUp 0.4s;
  animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  -ms-animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
  -o-animation-fill-mode: forwards;
  animation-delay: 0.4s;
  -webkit-animation-delay: 0.4s;
  -moz-animation-delay: 0.4s;
  -ms-animation-delay: 0.4s;
  -o-animation-delay: 0.4s;
}

.main-banner-slider .item.out .title{
  animation: FadeOutUp 0.4s;
  -webkit-animation: FadeOutUp 0.4s;
  -moz-animation: FadeOutUp 0.4s;
  -ms-animation: FadeOutUp 0.4s;
  -o-animation: FadeOutUp 0.4s;
  animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  -ms-animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
  -o-animation-fill-mode: forwards;
  animation-delay: 0.6s;
  -webkit-animation-delay: 0.6s;
  -moz-animation-delay: 0.6s;
  -ms-animation-delay: 0.6s;
  -o-animation-delay: 0.6s;
}

.main-banner-slider .item.out .subtitle{
  animation: FadeOutUp 0.4s;
  -webkit-animation: FadeOutUp 0.4s;
  -moz-animation: FadeOutUp 0.4s;
  -ms-animation: FadeOutUp 0.4s;
  -o-animation: FadeOutUp 0.4s;
  animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  -ms-animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
  -o-animation-fill-mode: forwards;
}


.main-banner-slider .item.out .content{
  animation: FadeOutUp 0.4s;
  -webkit-animation: FadeOutUp 0.4s;
  -moz-animation: FadeOutUp 0.4s;
  -ms-animation: FadeOutUp 0.4s;
  -o-animation: FadeOutUp 0.4s;
  animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  -ms-animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
  -o-animation-fill-mode: forwards;
  animation-delay: 0.2s;
  -webkit-animation-delay: 0.2s;
  -moz-animation-delay: 0.2s;
  -ms-animation-delay: 0.2s;
  -o-animation-delay: 0.2s;
}

.main-banner-slider .item.out .btn{
  animation: FadeOutDown 0.4s;
  -webkit-animation: FadeOutDown 0.4s;
  -moz-animation: FadeOutUp 0.4s;
  -ms-animation: FadeOutDown 0.4s;
  -o-animation: FadeOutDown 0.4s;
  animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  -ms-animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
  -o-animation-fill-mode: forwards;
  animation-delay: 0.4s;
  -webkit-animation-delay: 0.4s;
  -moz-animation-delay: 0.4s;
  -ms-animation-delay: 0.4s;
  -o-animation-delay: 0.4s;
}

.main-banner-slider{
  max-width: 768px;
  margin: 0 auto;
}
/* ============== close header & homepage banner css ====================================== */
.know-us{
  padding: 40px 0;
  background-color: #FDD500;
}
.know-us h4 {
  /*letter-spacing: 2px;*/
  padding: 0;
  margin: 0;
  font-family: "Merriweather", serif;
}
.intro{
  padding-top: 60px;
  text-align: center;
}
.site-section-title{
  /*padding-bottom: 15px;*/
  margin-top: 0;
  margin-bottom: 30px;
  color: #333333;
  text-align: center;
  text-transform: uppercase;
}
.some-intro{
  /*padding-bottom: 30px;*/
  /*color: #000000;*/
  text-align: center;
}
.yellow{
  color:#FDD500;

}
.white{
  color: #fff;
}
.capabilities{
  padding: 80px 0px;
}
.capabilities-post-header {
  margin: 30px 0px 20px;
  overflow: hidden;
  position: relative;
}
.capabilities-post-header ul {
  list-style-type: none;
  padding: 0;
  margin: -1px 0 0 -1px;
}
.capabilities-post-header ul:before,
.capabilities-post-header ul:after{
  content: "";
  display: table;
}
.capabilities-post-header ul:after{
  clear: both;
}
.capabilities-post-header:before{
  content: "";
  background: #FDD500;
  position: absolute;
  left: 0;
  right: 1px;
  bottom: 0;
  height: 4px;
}
.capabilities-post-header ul li {
  float: left;
  width: 33.33%;
}
.capabilities-post-header ul li:hover a {
  background-color: rgba(0,10,13,0.9);
}
.capabilities-post-header ul li.active a {
  background-color: #FDD500;
  border-color: #FDD500;
  color: #000000;
}
.capabilities-post-header ul li a {
  background-color: #000A0D;
  display: block;
  text-align: center;
  padding: 20px;
  font-family: 'Merriweather Sans', sans-serif;
  color: #FDD500;
  font-size: 18px;
  font-weight: bold;
  border-right: 1px solid #404242;
  border-top: 1px solid #404242;
}
.capabilities-post-header ul li i {
  font-size: 30px;
  padding-right: 14px;
  vertical-align: middle;
}
.capabilities-post-header ul li a:active,
.capabilities-post-header ul li a:focus,
.capabilities-post-header ul li a:visited {
  text-decoration: none;
}
.capabilities-post-warp {
  max-width: 900px;
  margin: 0 auto;
}
.capabilities-post {
  padding: 35px 0 0 0;
}
.capabilities-post-warp p {
  /*color: #4A4444;*/
  /*font-size: 14px;*/
  margin-top: 0px;
  /*padding-bottom: 30px;*/
  font-weight: 400;
}
.capabilities-post-warp img {
  padding-bottom: 25px;
}
.our-works {
  min-height: 400px;
  background: transparent;
  background-size: cover;
  background-repeat: no-repeat;
}
.our-work-slider {
  max-width: 992px;
  margin: 0 auto;
}
.our-work-slider .item{
  text-align: left;
}
/*.our-works .overlay {
position: absolute;
left: 0;
top: 0;
right: 0;
bottom: 0;
}
.overlay {
background-color: rgba(45, 49, 55, 0.7);
}*/
.works-wrap {
  padding: 80px 0;
}

.our-work-slider h2{
  color: #FDD500;
  padding-bottom: 10px;
  display: inline-block;
  border-bottom: 1px solid rgba(255, 255, 255, 0.6);
}
.our-work-slider p{
  color: #fff;
  display: block;
  margin-top: 0;
}

/* =================================== End carousel ============================================ */
.testimonials-wrap{
  background-image: url('../images/testimonial-bg.jpg');
  background-attachment: fixed;
  padding: 30px 0;
  color: #FFFFFF;
  text-align: center;
}
.testimonials-wrap h3 {
  color: #FFFFFF;
}
.testimonials-wrap h1{
  padding-top: 30px;
  color:#FFFFFF;
}
#testimonial-slider .item{
  display: block;
  padding: 0px 0px;
  margin: 5px;
  color: #FFF;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  text-align: center;
}
.profile-client p {
  color: #FFFFFF;
}
.profile-client h4{
  color: #FFFFFF;
}
.profile-client p a{
  color:#FFFFFF;
}
.owl-item,
.owl-item.loading{
  min-height: 100px;
}

.owl-theme .owl-controls .owl-page.active span, .owl-theme .owl-controls.clickable .owl-page:hover span {
  background: #97A2A6;
}

.owl-theme .owl-controls .owl-buttons div {
  background: #97A2A6;
}

/* ============================== */
.clients-wrap{
  background: #ffffff;
  padding: 80px 0;
}
/*#owl-demo .item{
	margin: 3px;
}
#owl-demo .item img{
	display: block;
	width: 100%;
	height: auto;
}*/
.clients-wrap img {
  width: 100%;
  max-width: 200px;
  margin: 0 auto;
  padding: 10px;
  border: 1px solid #E6E5E5;
}
/*.owl-buttons {
	display: none;
}*/
#clients-slider {
  padding: 25px 0px 0px;
}

#clients-slider .item{
  text-align: center;
}

/* ===================== start footer section css ============================= */
#main-footer{
  /*position: relative;
  z-index: 99;*/
}
.footer-focus{
  padding: 50px 0px;
  background-color: #2D3137;
}
.footer-focus h4{
  color: rgba(255, 255, 255, 0.8);
  margin-top: 0;
}
.footer-site-head{
  color: #fff;
  margin-top: 0;
  text-transform: uppercase;
}
.footer-site-head span{
  color: #FDD500;
}
.focus-highlight {
  padding-bottom: 45px;
}
.footer-focus-top ul{
  padding-left: 10px;
  padding-top: 10px;
  list-style-type: none;
}
.footer-focus-top ul li{
  padding-bottom: 5px;
  font-size: 12px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.7);
}
.footer-focus-top ul li:last-child{
  padding-bottom: 0;
}
.footer-focus-top ul li a{
  font-size: 12px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.7);
}
.footer-focus-top ul li a{
  text-decoration: none;
}
.footer-focus-top ul li a:hover{
  text-decoration: none;
  color: #FDD500;
}
span.newsletter{
  font-size: 12px;
  color: rgba(255, 255, 255, 0.7);
  padding-bottom: 15px;
  display: block;
}
span.contact-subheading{
  font-size: 14px;
  color: #FFFFFF;
  padding-bottom: 15px;
  display: block;
}
.subscribe .input-group .form-control {
  border-radius: 0;
  text-align: center;
}
.subscribe .input-group-btn .btn {
  color: #2D3137;
  text-align: center;
  background-color: #FDD500;
  border: 1px solid #FDD500;
  border-radius: 0px;
  padding-top: 7px;
  padding-bottom: 6px;
  font-size: 14px;
  font-weight: 400;
}
.subscribe .btn.loading:before{
  top: 8px;
}

.input-group-addon:last-child {
  text-decoration: none;
}
.footer-focus-top ul li a i {
  padding: 7px;
  background: #fff;
  border-radius: 50%;
  color: #2D3137;
  width: 30px;
  height: 30px;
  text-align: center;
  margin: 0px 10px 10px 0px;
  font-size: 14px;
}
.copyright{
  background-color: #242528;
  padding: 0px 0px 15px;
  border-bottom: 5px solid #2D3137;
  text-align: center;
  color: #fff;
}
.copyright p{
  font-size: 14px;
  margin-bottom: 0;
  opacity: 0.6;
  font-family: "Merriweather Sans", sans-serif;
  font-weight: 700;
}
/* ============= start about css =============================== */

#inner-page{
  background-size: cover;
  background: transparent;
  position: relative;
  /*min-height: 500px;*/
}
body.fixed-header #inner-page{
  padding-top: 100px;
}
#inner-page{
  padding-top: 100px;
//  color: #ffffff;
}
#inner-page .main-banner-content-wrap .main-banner-content h1{
  color: #FDD500;
  font-size: 36px;
  line-height: 36px;
  text-align: center;
  margin-bottom: 26px;
}
#inner-page .main-banner-content-wrap .main-banner-content{
  padding: 0 0 80px;
  color: #ffffff;
}
#inner-page .main-banner-content-wrap .main-banner-content p {
  text-align: center;
  width: 100%;
  margin: 0 auto;
  font-weight: 300;
  font-size: 18px;
  line-height: 26px;
}
#inner-page .map{
  min-height: 500px;
}
#inner-page .scroll-down-wrap{
  @include gradient-vertical( transparent, #ffffff );
  height: 150px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
#inner-page .scroll-down{
  color: #000A0D;
  display: block;
  max-width: 200px;
  margin: 80px auto 0 auto;
  text-align: center;
  cursor: pointer;
}
#inner-page .scroll-down p{
  margin-bottom: 10px;
}
.content-wrapper:not(:last-child){
  margin-bottom: 60px;
}
.site-section.head-offset{
  padding-top: 100px;
}
.site-section{
  padding: 80px 0;
  background: #ffffff;
}
.site-section.attach-top{
  padding-top: 0;
}
.site-section.grey{
  background: #eeeeee;
}
.site-content.with-sidebar:before,
.site-content.with-sidebar:after {
  content: '';
  display: table;
}
.site-content.with-sidebar:after{
  clear: both;
}
.site-content.with-sidebar .left-sidebar-wrap {
  float: left;
  width: 24%;
}
.site-content.with-sidebar .main-content{
  float: left;
  width: 73%;
  margin-left: 2.5%;
  overflow: hidden;
}
.left-sidebar-wrap{
  background-color: #000A0D;
  padding:25px;
}
.left-sidebar-wrap ul{
  padding-left: 0px;
}
.left-sidebar-wrap ul li{
  list-style-type: none;
  padding-bottom: 10px;
  border-bottom: 1px solid #6D6565;
  display: block;
  margin-bottom: 10px;
}
.left-sidebar-wrap ul li a{
  color: #fff;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 400;
}
.left-sidebar-wrap ul li a:active,
.left-sidebar-wrap ul li a:focus{
  text-decoration: none;
}
.left-sidebar-wrap ul li a span{
  color: #FFC30D;
}
ul.site-section-inner{
  padding-left: 0px;
}
ul.site-section-inner li{
  padding-bottom: 35px;
  margin-bottom: 35px;
  border-bottom: 2px solid #37353B;
  list-style-type: none;
}
ul.site-section-inner li:last-child{
  border-bottom: none;
}
h1.inner-site-section-title {
  margin-bottom: 40px;
  text-transform: uppercase;
}
h1.inner-site-section-title span{
  color: #FFC30D;
}
.inner-site-section p {
  /*color: #4A4444;*/
  font-size: 14px;
  /*padding-bottom: 10px;*/
  font-weight: 400;
  margin-top: 1.5rem;
  line-height: 2rem;
}

.btn-primary.white {
  background-color: #000A0D;
  border-color: #000A0D;
  color: #fff !important;
  text-transform: none;
  font-size: 14px;
  /*margin-top: 15px;*/
}
.inner-site-section img{
  margin-top: 1.5rem;
}

#our-work-filter.is_stuck{
  left: 0;
  right: 0;
  max-width: none;
  z-index: 9;
}

.contact-wrap{
  background: #000A0D;
  padding: 30px;
  color: rgba(255,255,255,0.9);
}

.contact-wrap h1{
  color: inherit;
  margin-top: 0;
  margin-bottom: 30px;
}
.about-details-inner h1{
  margin:0;
}
.about-details-inner h3{
  font-weight: normal;
  opacity: 0.8;
}
.about-mission{
  background-color: #FDD520;
  padding: 50px;
}
.about-mission p{
  text-align: center;
}
.team-wrapper{
  width: 80%;
}
.our-team{
  margin-top: 40px;
  margin-bottom: 40px;
  position: relative;
}
.our-team .team-member{
  text-align:center;
  padding: 30px;
  overflow: hidden;

}
.team-overlay{
  position:absolute;
  top:100%;
  left: 0;
  right: 0;
  background-color:#FDD520;
  width: 100%;
  height: 100%;
  transition: .5s ease;
  padding:20px;
}
.team-member:hover .team-overlay{
  height: 100%;
}
.team-member:hover .team-overlay{
  top:0px;
}
.our-team .team-member img{
  height:150px;
  width: 150px;
  border-radius: 50%;
  overflow: hidden;
  border-width: 2px solid #000;
}
.our-team .team-member:hover{

}
.our-team .team-member h3{
  font-weight: normal;
}
.our-team .team-member h5{
  font-weight: normal;
  color: #444444;
  opacity: 0.8;
}
.our-team .team-member p{
  margin: 0;
  padding: 0;
  font-size: 12px;
  line-height: 2em;
}
.our-team .team-member span{
  font-size: 14px;
  font-style: italic;
  margin-bottom:20px;
}
.team-overlay .fa{
  font-size: 18px;
}
.empty{
  border-bottom: 2px solid #FDD520;
  width: 30%;
  text-align: center;
  margin: 0 auto;
  display: block;
}
/*service-card*/
.service-card-wrap{
  margin-top: -30px;
}
.service-card{
  background: #ffffff;
  margin-top: 30px;
}
.service-card .image{
  background: #FDD500;
  /*background: #2AAAD1;*/
  min-height: 200px;
  text-align: center;
}
.service-card .image img{
  display: inline-block;
  width: 150px;
  margin-top: 25px;
 transition: transform 0.2s;
}
.service-card:hover .image img{
  transform: scale(1.2, 1.2);
}
.service-card .content{
  padding: 20px;
}

.service-card .content h3{
  margin-top: 0;
  font-weight: 700;
  margin-bottom: 18px;
}

.service-card .content p:first-child{
  margin-top: 0;
}

.service-card .content p:last-child{
  margin-bottom: 0;
}

/*Proceed card*/

.process-card-wrap{
  margin-top: -30px;
}

.process-card{
  /*color: #ffffff;*/
  background: #FDD500;
  /*background: #2AAAD1;*/
  padding: 20px;
  min-height: 300px;
  margin-top: 30px;
}

.process-card h3{
  /*color: #ffffff;*/
  margin-top: 0;
}

.process-card p{
  margin-bottom: 0;
}

.process-card img{
  width: 100%;
}

.process-card .icon{
  max-width: 70px;
  float: left;
  margin-right: 30px;
}

/*Tool Card*/
.tool-card-wrap{
  margin-top: -30px;
}
.tool-card{
  height: 100px;
  border: 1px solid #e1e1e1;
  margin-top: 30px;
  transition: border-color 0.2s;
}

.tool-card:hover{
  border-color: #bababa;
}

.tool-card img{
  display: block;
  width: 100%;
  max-width: 200px;
  margin: 15px auto;
}
/* ========= portfolio css start ==================== */
.iso-filter-wrap{
  /*width: 100%;*/
  /*max-width: 600px;*/
  overflow: hidden;
  margin: 0 auto 30px auto;
}
.iso-filter{
  padding-left: 0;
  list-style-type: none;
  padding-bottom: 20px;
  text-align: center;
  margin: -1px 0 0 -1px;
}
.iso-filter:before,
.iso-filter:after{
  content: '';
  display: table;
}
.iso-filter:after{
  clear: both;
}
.iso-filter li {
  width: 16.66%;
  float:left;
  cursor: pointer;
}
.iso-filter a.active{
  background: #FDD500;
  color: #000A0D;
  border-color: #FDD500;
}
.iso-filter a{
  display: block;
  padding: 10px 10px;
  background: #000A0D;
  color: #ffffff;
  font-family: "Merriweather Sans", sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  border-right: 1px solid #404242;
  border-top: 1px solid #404242;
}
.iso-filter a:not(.active):hover{
  background: rgba(0,10,13, 0.9);
}
.iso-grid{
  padding: 0;
  margin: 0;
}
.iso-grid:before,
.iso-grid:after{
  content: '';
  display: table;
}
.iso-grid li {
  float: left;
  width: 33.33%;
  list-style-type: none;
  padding: 15px;
  margin: 0;
  transition-duration: 0.7s;
}
.iso-grid li:hover{
  background: #f1f1f1;
}
.iso-grid li figure{
  overflow: hidden;
}
.iso-grid figure,
.iso-grid img {
  width: 100%;
}
.iso-grid figure{
  margin-bottom: 20px;
}
.iso-grid p{
  font-size: 14px;
  margin-top: 0;
  line-height: 21px;
}
.iso-grid img,
.iso-grid .overlay {
  -webkit-transition: all 275ms ease-in;
  -moz-transition: all 275ms ease-in;
  -ms-transition: all 275ms ease-in;
  -o-transition: all 275ms ease-in;
  transition: all 275ms ease-in
}
.iso-grid a:hover img,
.iso-grid .overlay {
  -webkit-transform: scale(2);
  -moz-transform: scale(2);
  -ms-transform: scale(2);
  -o-transform: scale(2);
  transform: scale(2)
}
.iso-grid a {
  display: block;
  position: relative;
}
.iso-grid .overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  text-align: center;
  overflow: hidden
}
.iso-grid a:hover .overlay {
  background: rgba(0,0,0,0.5);
  opacity: 1;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1)
}
.iso-grid .overlay span {
  position: absolute;
  height: 50px;
  width: 50px;
  padding: 13px;
  border-radius: 25px;
  background: black;
  color: white;
  font-size: 21px;
  opacity: 0.8;
  top: 50%;
  left: 50%;
  margin-top: -25px;
  margin-left: -25px;
}
/* ================== contact us page start css ==================== */

.embed-responsive.contact-map {
  width: auto;
  height: 350px;
  margin:25px 0px 35px;
}
.form-control.quick {
  height: 50px;
  border-radius: 0px;
}
textarea.form-control.comment {
  height: 115px;
  border-radius: 0px;
}
.quick-contact {
  margin: 25px 0px 35px;
}
.other-contact-information {
  margin: 25px 0px 0px;
  text-align: center;
}
.other-contact-information i.highlight{
  font-size: 25px;
  background: #FDD500;
  color: #fff;
  padding: 15px;
  border-radius: 50%;
  width: 55px;
  height: 55px;
}
.other-contact-information ul{
  padding-left: 0;
}
.other-contact-information ul li{
  list-style-type: none;
  font-weight: 400;
  padding: 15px 0;
  display:inline-block;
}

ul.social-links li a i {
  padding: 7px;
  background: #2D3137;
  border-radius: 50%;
  color: #fff;
  width: 30px;
  height: 30px;
  text-align: center;
  margin: 0 10px;
  font-size: 14px;
}
ul.social-links li a i.fa-facebook:hover{
  background: #3B5999;
}
ul.social-links li a i.fa-twitter:hover{
  background: #00ACEE;
}
ul.social-links li a i.fa-google-plus:hover{
  background: #D24737;
}
ul.social-links li a i.fa-instagram:hover{
  background: #3E729A;
}

/*Slick Slider Override*/
.slick-slider.boxed{
  /*padding: 0 20px;*/
}
.slick-slide:focus{
  outline: none;
}
.slick-arrow{
  position: absolute;
  top: 50%;
  margin-top: -65px;
  background: rgba(223, 237, 242, 0.5);
  color: rgba(0, 10, 13, 0.7);
  border: none;
  height: 40px;
  width: 40px;
  border-radius: 20px;
  opacity: 0.6;
  font-size: 18px;
  /*box-shadow: 1px 1px 1px #ffffff inset;*/
}

.slick-arrow:focus{
  outline: none;
}

.slick-arrow:hover{
  opacity: 0.9;
}

.slick-prev{
  left: -70px;
}

.slick-next{
  right: -70px;
}
.slick-dots:before,
.slick-dots:after{
  content: '';
  display: table;
}
.slick-dots:after{
  clear:both;
}
.slick-dots{
  list-style-type: none;
  padding: 0;
  display: inline-block !important;
  margin-top: 70px;
}
.slick-dots li{
  float: left;
}
.slick-dots li button{
  height: 5px;
  overflow: hidden;
  background: #C3CFD4;
  box-shadow: none;
  border: none;
  width: 30px;
  opacity: 0.5;
}
.slick-dots li button:focus{
  outline: none;
}
.slick-dots li.slick-active button{
  opacity: 0.9;
}
.slick-dots li:not(:last-child){
  margin-right: 2px;
}
.slick-center{
  transform: scale(1.2, 1.2);
}

/*Modal Overrides*/

.modal{
  background: rgba(0,10,13, 0.8);
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.modal-dialog{
  /*margin: 0;*/
}
.modal-content{
  border-radius: 0;
  box-shadow: none;
}
.modal-fullscreen .modal-dialog{
  max-width: none;
  width: 100%;
  margin: 0;
  margin-top: 5%;
}
.chosen-modal.modal.fade:not(.in) .modal-dialog{
  transition-duration: 0.2s;
  transform: translate(0, 100%);
  -webkit-transform: translate(0, 100%);
  -moz-transform: translate(0, 100%);
  -ms-transform: translate(0, 100%);
  -o-transform: translate(0, 100%);
}
.modal-dark .modal-content{
  background: transparent;
  border: none;
}

.modal-dark .modal-content h1,
.modal-dark .modal-content h2,
.modal-dark .modal-content h3,
.modal-dark .modal-content h4,
.modal-dark .modal-content h5,
.modal-dark .modal-content h6{
  color: rgba(255, 255, 255, 0.9);
}

.modal-dark .text-bright{
  color: rgba(255, 255, 255, 0.9) !important;
}

.modal-dark .modal-content{
  color: rgba(255, 255, 255, 0.6);
}

.modal-dark .text-dark{
  color: rgba(255, 255, 255, 0.6) !important;
}

.modal-dark .modal-content .close{
  color: #ffffff;
  opacity: 0.6;
}
.modal-dark .modal-content .close:hover,
.modal-dark .modal-content .close:active,
.modal-dark .modal-content .close:focus{
  color: #ffffff;
  opacity: 0.8;
}

.modal-dark .modal-header,
.modal-dark .modal-footer{
  border-color: rgba(255, 255, 255, 0.2);
}

.modal-dark .form-modernize .form-control{
  color: rgba(255, 255, 255, 0.9);
}

/*Form overrides*/
.form-modernize .title h4{
  font-weight: 700;
}
.form-modernize .title h4,
.form-modernize .title p{
  margin: 0;
}
.form-modernize .title p{
  font-size: 14px;
  font-weight: 300;
}
.form-modernize .title {
  margin-bottom: 20px;
}

.form-modernize .form-group{
  margin-bottom: 30px;
}
.form-modernize .form-control{
  background: transparent;
  font-size: 21px;
  line-height: 31px;
  font-weight: 300;
  padding: 5px 30px 0 30px;
  border-radius: 0;
  border-color: rgba(255, 255, 255, 0.6);
  color: inherit;
  height: 70px;
}

.form-modernize textarea.form-control{
  padding-top: 20px;
  padding-bottom: 20px;
  max-width: 100%;
  min-height: 300px;
}

.form-modernize .form-control:focus,
.form-modernize .form-control:active{
  outline: none !important;
  box-shadow: none;
  border-color: #FDD500;
}

.form-modernize .help-block{
  text-align: left !important;
  font-size: 14px;
  line-height: 21px;
}

.form-modernize ::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.6;
}

.form-modernize :-moz-placeholder { /* Firefox 18- */
  color: inherit;
  opacity: 0.6;
}

.form-modernize ::-moz-placeholder {  /* Firefox 19+ */
  color: inherit;
  opacity: 0.6;
}

.form-modernize :-ms-input-placeholder {
  color: inherit;
  opacity: 0.6;
}

/* ============ Our Customer Css Starts=============== */
.customer-wrap{
  margin-top:30px;
  margin-bottom: 20px;
}
.customer-block{
  border: 1px solid #e1e1e1;
  margin-bottom: 20px;
}
.customer-block .image{
  background-color: #f7f8f9;
  text-align: center;
  padding: 15px;
}
.customer-block .content{
  padding:20px;
}
.customer-block .content h4{
  margin-top: 0;
  margin-bottom: 18px;
  margin-top: 10px;
  color: #555;
  opacity: 0.8;
}
.customer-block .content p{
  font-size: 14px;
  font-weight: normal;
  opacity: 0.6;
}

/*Travel CMS*/
.travelcms-intro{
  margin-top: 50px;
}
.travelcms-features{
  margin-top: 50px;
  padding: 50px 0;
  background-color: #FAFAFA;
}
.travelcms-why{
  margin-top:50px;
}
.travelcms-dashboard{
  background-color: #FAFAFA;
  padding: 30px 0;
}
.why-bstravel{
  background-color: #943bea;
  color: #FFFFFF;
  padding: 50px;
}
.why-bstravel h2{
  text-align: left;
  color: #FFFFFF;
}

.why-bstravel ul{
}
.why-bstravel ul li{
  line-height: 2.2rem;
  margin-top: 0.5rem;
  font-size: 16px;
}
.key-features h2{
  text-align: left;
}
.key-features{
  line-height: 2.2rem;
  margin-top: 0.5rem;
  font-size: 16px;
}
.key-features ul{
  list-style-type: none;
}
.key-features ul li{
}
.key-features ul li i{
  color: #943bea;
}
.travelcms-cta{
  text-align: center;
  padding: 50px;
  background-color:#EEEEEE;
}
.travelcms-cta p{
  padding:0;
}
.travel-feature{
  text-align: center;
}
.travel-feature h2{
  margin-top: 0;
  padding-top:0;
}
.travel-feature .feature-item i{
  font-size: 32px;
  color: #943bea;
  border-radius: 50%;
  padding: 15px;
  text-align: center;
}
.feature-row{
  border-bottom: 1px solid #e0dede;
}
.feature-row:last-of-type{
  border-bottom: none;
}

.travel-feature .feature-item {
  min-height: 250px;
  padding-top: 20px;
  border-right: 1px solid #e0dede;
}
.travel-feature .feature-item:last-of-type{
  border-right: none;
}
.travel-feature .feature-item h4{

}
.travel-feature .feature-item p{
  color:#6d6e70;
  font-size: 12px;
  line-height: 18px;
}
.travelcms-dashboard .screen-shot h3{
  text-align: center;
}
.travelcms-dashboard .screen-shot p{
  text-align: center;
}
.travel-cms-intro{
  padding-top:80px;
}
.site-section-travelcms{
  padding: 50px 0px;
  background-color: #EEEEEE;
}
.travelcms-why{
  padding-bottom: 80px;
}
/*our work process*/
.our-mission-wrap{
  background-color: #FFDD00;
  text-align: center;
}
.work-process-wrap{
  background-color: #F5F5F5;
}
.tab-inner{
  padding-top: 50px;
  max-width: 85%;
  margin: 0 auto;
}
.tab-content-wrap .nav-tabs{
  border-bottom: none;
  padding: 25px 0 15px;
}
.tab-content-wrap .nav-tabs li{
  float: left;
  width: 20%;
}
.tab-content-wrap ul.nav-tabs li a{
  width: 75px;
  height: 75px;
  border-radius: 50%;
  background-color: #FFDD00;
  padding: 15px;
  text-align: center;
  font-size: 16px;
  margin: 0 auto;
}
.tab-content-wrap ul.nav-tabs li a i {
  color: #444444;
}
.tab-content-wrap ul.nav-tabs li a{
  color:#444444;
  opacity:0.8;
}
.tab-content-wrap ul.nav-tabs li.active a{
  color:#000000;
  font-weight: bold;
}
.tab-content-wrap
.nav-tabs>li.active>a, .nav-tabs>li.active>a:focus, .nav-tabs>li.active>a:hover{
  width: 75px;
  height: 75px;
  border-radius: 50%;
  background-color: #FFDD00;
  padding: 15px;
  text-align: center;
  font-size: 16px;
  color:#444444;
}

.tab-content-wrap ul.nav-tabs li a span{
  display: block;
  font-size: 36px;
  margin-bottom: 15px;
  color:#333333;
}
.tab-content-wrap ul.nav-tabs li.active a span{
  color: #000000;
  font-weight: bold;
}

.fade {
  opacity: 2;
}
/*-- Fade in up --*/
@keyframes FadeInUp {
  from {
    opacity: 0;
    transform: translateY( 50px );
  }
  to {
    opacity: 1;
    transform: translateY( 0 );
  }
}

@-webkit-keyframes FadeInUp {
  from {
    opacity: 0;
    transform: translateY( 50px );
  }
  to {
    opacity: 1;
    transform: translateY( 0 );
  }
}

@-moz-keyframes FadeInUp {
  from {
    opacity: 0;
    transform: translateY( 50px );
  }
  to {
    opacity: 1;
    transform: translateY( 0 );
  }
}

@-o-keyframes FadeInUp {
  from {
    opacity: 0;
    transform: translateY( 50px );
  }
  to {
    opacity: 1;
    transform: translateY( 0 );
  }
}

@-ms-keyframes FadeInUp {
  from {
    opacity: 0;
    transform: translateY( 50px );
  }
  to {
    opacity: 1;
    transform: translateY( 0 );
  }
}

/*-- Fade out up --*/
@keyframes FadeOutUp {
  from {
    opacity: 1;
    transform: translateY( 0 );
  }
  to {
    opacity: 0;
    transform: translateY( -50px );
  }
}

@-webkit-keyframes FadeOutUp {
  from {
    opacity: 1;
    transform: translateY( 0 );
  }
  to {
    opacity: 0;
    transform: translateY( -50px );
  }
}

@-moz-keyframes FadeOutUp {
  from {
    opacity: 1;
    transform: translateY( 0 );
  }
  to {
    opacity: 0;
    transform: translateY( -50px );
  }
}

@-o-keyframes FadeOutUp {
  from {
    opacity: 1;
    transform: translateY( 0 );
  }
  to {
    opacity: 0;
    transform: translateY( -50px );
  }
}

@-ms-keyframes FadeOutUp {
  from {
    opacity: 1;
    transform: translateY( 0 );
  }
  to {
    opacity: 0;
    transform: translateY( -50px );
  }
}

/*--- Fade in down ---*/
@keyframes FadeInDown {
  from {
    opacity: 0;
    transform: translateY( -50px );
  }
  to {
    opacity: 1;
    transform: translateY( 0 );
  }
}

@-webkit-keyframes FadeInDown {
  from {
    opacity: 0;
    transform: translateY( -50px );
  }
  to {
    opacity: 1;
    transform: translateY( 0 );
  }
}

@-moz-keyframes FadeInDown {
  from {
    opacity: 0;
    transform: translateY( -50px );
  }
  to {
    opacity: 1;
    transform: translateY( 0 );
  }
}

@-o-keyframes FadeInDown {
  from {
    opacity: 0;
    transform: translateY( -50px );
  }
  to {
    opacity: 1;
    transform: translateY( 0 );
  }
}

@-ms-keyframes FadeInDown {
  from {
    opacity: 0;
    transform: translateY( -50px );
  }
  to {
    opacity: 1;
    transform: translateY( 0 );
  }
}

/*--- Fade out down ---*/
@keyframes FadeOutDown {
  from {
    opacity: 1;
    transform: translateY( 0 );
  }
  to {
    opacity: 0;
    transform: translateY( 50px );
  }
}

@-webkit-keyframes FadeOutDown {
  from {
    opacity: 1;
    transform: translateY( 0 );
  }
  to {
    opacity: 0;
    transform: translateY( 50px );
  }
}

@-moz-keyframes FadeOutDown {
  from {
    opacity: 1;
    transform: translateY( 0 );
  }
  to {
    opacity: 0;
    transform: translateY( 50px );
  }
}

@-o-keyframes FadeOutDown {
  from {
    opacity: 1;
    transform: translateY( 0 );
  }
  to {
    opacity: 0;
    transform: translateY( 50px );
  }
}

@-ms-keyframes FadeOutDown {
  from {
    opacity: 1;
    transform: translateY( 0 );
  }
  to {
    opacity: 0;
    transform: translateY( 50px );
  }
}

/*--- Fade in right ---*/
@keyframes FadeInRight {
  from{
    opacity: 0;
    transform: translateX( 50px );
  }

  to{
    opacity: 1;
    transform: translateX( 0 );
  }
}

@-webkit-keyframes FadeInRight {
  from{
    opacity: 0;
    transform: translateX( 50px );
  }

  to{
    opacity: 1;
    transform: translateX( 0 );
  }
}

@-moz-keyframes FadeInRight {
  from{
    opacity: 0;
    transform: translateX( 50px );
  }

  to{
    opacity: 1;
    transform: translateX( 0 );
  }
}

@-ms-keyframes FadeInRight {
  from{
    opacity: 0;
    transform: translateX( 50px );
  }

  to{
    opacity: 1;
    transform: translateX( 0 );
  }
}

@-o-keyframes FadeInRight {
  from{
    opacity: 0;
    transform: translateX( 50px );
  }

  to{
    opacity: 1;
    transform: translateX( 0 );
  }
}

/*-- Fade out left ---*/
@keyframes FadeOutLeft {
  from{
    opacity: 0;
    transform: translateX( 50px );
  }

  to{
    opacity: 1;
    transform: translateX( 0 );
  }
}

@-webkit-keyframes FadeOutLeft {
  from{
    opacity: 1;
    transform: translateX( 0 );
  }

  to{
    opacity: 0;
    transform: translateX( -50px );
  }
}

@-moz-keyframes FadeOutLeft {
  from{
    opacity: 1;
    transform: translateX( 0 );
  }

  to{
    opacity: 0;
    transform: translateX( -50px );
  }
}

@-ms-keyframes FadeOutLeft {
  from{
    opacity: 1;
    transform: translateX( 0 );
  }

  to{
    opacity: 0;
    transform: translateX( -50px );
  }
}

@-o-keyframes FadeOutLeft {
  from{
    opacity: 1;
    transform: translateX( 0 );
  }

  to{
    opacity: 0;
    transform: translateX( -50px );
  }
}

h1.site-section-title span{
  color: #fdd500;
}
h1.site-section-title center{
  color: #fdd500;
}

/* ======================= service page start css ================== */
.services{
  padding: 80px 0px;
}

.service-inner{
  background-color: #FFDD00;
  padding: 50px;
}
.service-inner h1{
  text-align: left;
}
.service-block{
  margin-bottom: 25px;
  background-color: #fff;
}
.service-post{
  display: block;
  min-width: 280px;
  min-height: 260px;
  padding: 25px;
  background-color: #E7E5E5;
  text-align: center;
  float: left;
  margin: 5px 5px;
  overflow: hidden;
  transition: .8s linear;
  -webkit-transition: .8s linear;
  -moz-transition: .8s linear;
  -ms-transition: .8s linear;
  -o-transition: .8s linear;
  color: #000;
}
.service-post .icon{
  font-size: 100px;
  margin: 15px auto 0;
  transition: transform .2s;
  max-height: 190px;
}
.service-post:hover .icon{
  transform: scale(1.2);
}
.service-post:hover{
  background-color: #FFDD00;
}
.service-post span{
  text-transform: uppercase;
  color: #000000;
  font-weight: 600;
}
.service-post .icon{
  font-size: 100px;
  color: #000;
  margin: 15px auto 0;
  transition: transform .2s;
  max-height: 190px;
}
.service-post:hover .icon{
  transform: scale(1.2);
}
.service-post:hover{
  background-color: #FFDD00;
}
.service-post span{
  text-transform: uppercase;
  color: #000000;
  font-weight: 600;
}

/* Why Choose us */
.why-choose-us{
  padding: 0 0;
}
.checklist-post {

}
.why-choose-us .icon{
  width: 70px;
  height: 70px;
  padding: 15px;
  margin-right: 20px;
  float: left;
  border-radius: 50%;
  font-size: 36px;
  background-color: #FFDD00;
}
.checklist-content{
  float: left;
  max-width: 450px;
}
.checklist-content h5{
  margin-top: 0;
  font-weight: 400;
}
.checklist-content p{
  color: #838383;
}
/*end of Why CHoose uS */


/* =====================Scroll to=====================*/
a.scrollTo, a.scrollTo:visited, a.scrollTo:focus {
  display: block;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
}
.know-more {
  position: absolute;
  bottom: 50px;
  left: 50%;
  margin-left: -17px;
  width: 34px;
  height: 66px;
  background: url(../images/icons/scroll.png) no-repeat;
  background-size: contain;
  background-position: center;
  animation: bounce 1.5s infinite linear;
  -webkit-animation: bounce 1.5s infinite linear;
  -moz-animation: bounce 1.5s infinite linear;
  -o-animation: bounce 1.5s infinite linear;
  -ms-animation: bounce 1.5s infinite linear;
}

//Google Map
.map-info-window{
  color: #000000;
  font-weight: 400;
}

/*Animation*/

@keyframes bounce{
  0%{
    -webkit-transform:translateY(6px);
    -moz-transform:translateY(6px);
    -ms-transform:translateY(6px);
    -o-transform:translateY(6px);
    transform:translateY(6px);
    opacity:1
  }
  50%{
    -webkit-transform:translateY(3px);
    -moz-transform:translateY(3px);
    -ms-transform:translateY(3px);
    -o-transform:translateY(3px);
    transform:translateY(3px);
    opacity:.4
  }
  100%{
    -webkit-transform:translateY(6px);
    -moz-transform:translateY(6px);
    -ms-transform:translateY(6px);
    -o-transform:translateY(6px);
    transform:translateY(6px);
    opacity:1
  }
}

@-webkit-keyframes bounce{
  0%{
    -webkit-transform:translateY(6px);
    -moz-transform:translateY(6px);
    -ms-transform:translateY(6px);
    -o-transform:translateY(6px);
    transform:translateY(6px);
    opacity:1
  }
  50%{
    -webkit-transform:translateY(3px);
    -moz-transform:translateY(3px);
    -ms-transform:translateY(3px);
    -o-transform:translateY(3px);
    transform:translateY(3px);
    opacity:.4
  }
  100%{
    -webkit-transform:translateY(6px);
    -moz-transform:translateY(6px);
    -ms-transform:translateY(6px);
    -o-transform:translateY(6px);
    transform:translateY(6px);
    opacity:1
  }
}

@-moz-keyframes bounce{
  0%{
    -webkit-transform:translateY(6px);
    -moz-transform:translateY(6px);
    -ms-transform:translateY(6px);
    -o-transform:translateY(6px);
    transform:translateY(6px);
    opacity:1
  }
  50%{
    -webkit-transform:translateY(3px);
    -moz-transform:translateY(3px);
    -ms-transform:translateY(3px);
    -o-transform:translateY(3px);
    transform:translateY(3px);
    opacity:.4
  }
  100%{
    -webkit-transform:translateY(6px);
    -moz-transform:translateY(6px);
    -ms-transform:translateY(6px);
    -o-transform:translateY(6px);
    transform:translateY(6px);
    opacity:1
  }
}

@-o-keyframes bounce{
  0%{
    -webkit-transform:translateY(6px);
    -moz-transform:translateY(6px);
    -ms-transform:translateY(6px);
    -o-transform:translateY(6px);
    transform:translateY(6px);
    opacity:1
  }
  50%{
    -webkit-transform:translateY(3px);
    -moz-transform:translateY(3px);
    -ms-transform:translateY(3px);
    -o-transform:translateY(3px);
    transform:translateY(3px);
    opacity:.4
  }
  100%{
    -webkit-transform:translateY(6px);
    -moz-transform:translateY(6px);
    -ms-transform:translateY(6px);
    -o-transform:translateY(6px);
    transform:translateY(6px);
    opacity:1
  }
}

@-ms-keyframes bounce{
  0%{
    -webkit-transform:translateY(6px);
    -moz-transform:translateY(6px);
    -ms-transform:translateY(6px);
    -o-transform:translateY(6px);
    transform:translateY(6px);
    opacity:1
  }
  50%{
    -webkit-transform:translateY(3px);
    -moz-transform:translateY(3px);
    -ms-transform:translateY(3px);
    -o-transform:translateY(3px);
    transform:translateY(3px);
    opacity:.4
  }
  100%{
    -webkit-transform:translateY(6px);
    -moz-transform:translateY(6px);
    -ms-transform:translateY(6px);
    -o-transform:translateY(6px);
    transform:translateY(6px);
    opacity:1
  }
}
